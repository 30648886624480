/** @jsx jsx */
import React, { Fragment, Component }  from "react"
import { jsx } from "theme-ui"
import VisibilitySensor from 'react-visibility-sensor';

type ProjectCardProps = {
  link: string
  title: string
  children: React.ReactNode
  bg: string
}

const ProjectCard = ({ link, title, children, bg }: ProjectCardProps) => (

  <a
    href={link}
    target="_blank"
    rel="noreferrer noopener"
    sx={{
      width: `100%`,
      boxShadow: `lg`,
      position: `relative`,
      alignItems: `center`,
      textAlign: `center`,
      textDecoration: `none`,
      borderRadius: `lg`,
      px: 4,
      py: [4, 5],
      color: `white`,
      background: bg || `none`,
      transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
      "&:hover": {
        color: `white !important`,
        transform: `translateY(-5px)`,
        boxShadow: `xl`,
      },
    }}
  >
    <div
      sx={{
        textTransform: `uppercase`,
        letterSpacing: `wide`,
        pt: 4,
        fontSize: [4, 5],
        fontWeight: `medium`,
        textShadow: `1px 1px 5px black`,
        marginBottom: `5`,
        lineHeight: 1,
      }}
    >
      {title}
      <VisibilitySensor>
            {({isVisible}) =>
            <Fragment>
            <div id="omg"><img src="../1x1-00ff007f.png" height="1"/>{isVisible ? document.getElementById('menuoquefazemos').classList.add('menuativo') : document.getElementById('menuoquefazemos').classList.remove('menuativo')} </div>
            </Fragment>
            }
     </VisibilitySensor>
    </div>

    <div sx={{ opacity: 0.85, textShadow: `0 2px 10px rgba(0, 0, 0, 0.3)` }}>{children}</div>
  </a>

)

export default ProjectCard
